import { MetadataUIValueModel } from "@/components/configuration/MetadataUIViewModel";
import { Condition } from "@/services/configuration-service";
import { EQUALS, NOT_EQUALS, NOT_NULL } from "../configuration/configuration-constants";

function ConditionMatched(
  andPredicate: Condition,
  metadataValues: MetadataUIValueModel[]
): boolean {
  const metadataValue = metadataValues.filter(x => x.key == andPredicate.path);
  if (metadataValue.length > 0) {
    switch (andPredicate.predicate) {
      case EQUALS: {
        if (metadataValue[0].value === andPredicate.value) {
          return true;
        }
        break;
      }
      case NOT_EQUALS: {
        if (metadataValue[0].value !== andPredicate.value) {
          return true;
        }
        break;
      }
      case NOT_NULL: {
        if (metadataValue[0].value) {
          return true;
        }
        break;
      }
    }
  }
  return false;
}

function IsRequiredToContinue(
  metaDataValue: MetadataUIValueModel,
  metadataValues: MetadataUIValueModel[]
): boolean {
  if (metaDataValue.isRequired === true) return true;

  if (metaDataValue.isRequired === null && metaDataValue.requiredConditions) {
    for (const orPredicate of metaDataValue.requiredConditions) {
      let allAndPredicatesAreTrue = true;
      for (const andPredicate of orPredicate.andPredicates) {
        if (!ConditionMatched(andPredicate, metadataValues)) {
          allAndPredicatesAreTrue = false;
          break;
        }
      }
      if (allAndPredicatesAreTrue) return true;
    }
  }

  return false;
}

export function checkIfValidInput(
  metaDataValue: MetadataUIValueModel,
  maybeInput: any,
  metadataValues: MetadataUIValueModel[]
): string | boolean {
  if (maybeInput) return true;
  if (IsRequiredToContinue(metaDataValue, metadataValues)) return "This is required to continue";
  return true;
}

export function isMandatory(
  metaDataValue: MetadataUIValueModel,
  maybeInput: any,
  metadataValues: MetadataUIValueModel[]
) {
  if (maybeInput) return false;

  if (IsRequiredToContinue(metaDataValue, metadataValues)) return true;

  return false;
}
