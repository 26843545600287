























































import { Vue, PropSync, Component, Watch, Prop, Inject } from "vue-property-decorator";
import ConfigurationService, {
  AllStatusesConfig,
  SingleStatusConfig
} from "@/services/configuration-service";
import config from "@/config";
import { MetadataUIValueModel } from "@/components/configuration/MetadataUIViewModel";
import { checkIfValidInput, isMandatory } from "@/components/helper/MetadataValidator";

// MetadataUIValueModel

import { VuetifyForm } from "@/VuetifyTypes";
import { MetadataValuesModel } from "@/services/status-service";
import { CaseStatusEntry } from "@/models/case-maintenance.d";

@Component({
  inheritAttrs: false
})
export default class StatusConfigDialog extends Vue {
  @Inject() ConfigurationService!: ConfigurationService;

  @PropSync("dialog", { required: true }) dialogProp!: boolean;
  @Prop({ required: true }) myConfigTemplate!: SingleStatusConfig;
  @Prop() model: CaseStatusEntry | undefined;
  statusMetadataConfig: AllStatusesConfig = { statuses: [] };

  async mounted() {
    this.statusMetadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
  }

  getStatusName() {
    return this.myConfigTemplate
      ? config.caseStatus.find(x => x.value == this.myConfigTemplate.id)?.name
      : "";
  }

  metadataValues: MetadataUIValueModel[] = [];
  note = "";
  formValid = false;

  @Watch("myConfigTemplate", { deep: true })
  resetModel() {
    this.note = "";

    this.metadataValues = this.myConfigTemplate.metadata.map(meta => {
      return {
        key: meta.name,
        value: "",
        displayValues: meta.values ?? [],
        type: meta.type,
        isRequired: meta.isRequired,
        requiredConditions: meta.requiredConditions ?? []
      };
    });

    this.loadValues();
  }

  doCheckIfValidInput(metaDataValue: MetadataUIValueModel, maybeInput: any): string | boolean {
    return checkIfValidInput(metaDataValue, maybeInput, this.metadataValues);
  }
  itemClass(metaDataValue: MetadataUIValueModel) {
    return {
      mandatory: isMandatory(metaDataValue, metaDataValue.value, this.metadataValues)
    };
  }

  validateNoteField(noteIsRequired: boolean, maybeInput: any) {
    if (maybeInput) return true;
    if (!noteIsRequired) return true;

    return "You must add a note to continue";
  }

  save() {
    const castedForm = this.$refs.form as VuetifyForm;

    if (!castedForm.validate()) {
      return;
    }

    if (
      !this.note &&
      this.metadataValues.filter(x => {
        if (!this.model?.metadata) return true;

        const matchingModelValue = this.model.metadata.filter(y => x.key == y.key);

        if (matchingModelValue && matchingModelValue.length > 0) {
          if (matchingModelValue[0].value == x.value) return false;
        }

        return true;
      }).length == 0
    ) {
      this.cancel();
      return;
    }

    const modelToSendBack: MetadataValuesModel = {
      note: this.note,
      metadata: this.metadataValues.map(x => {
        const selectedDisplayName = this.myConfigTemplate.metadata
          .find(y => y.name == x.key)
          ?.values.find(z => z.value == x.value)?.displayName;
        return {
          key: x.key,
          value: x.value,
          displayValue: selectedDisplayName ? selectedDisplayName : ""
        };
      })
    };
    this.note = "";
    this.$emit("update-case-status", modelToSendBack);
  }

  cancel() {
    const castedForm = this.$refs.form as VuetifyForm;

    if (castedForm.resetValidation) castedForm.resetValidation();
    this.$emit("cancel");
    this.resetModel();
  }

  @Watch("dialogProp")
  loadValues() {
    this.metadataValues.forEach(x => (x.value = ""));
    if (this.dialogProp) {
      if (this.model?.metadata) {
        this.model.metadata.map(metadata => {
          const matching = this.metadataValues.find(x => x.key == metadata.key);
          if (matching) matching.value = metadata.value;
        });
      }
    }
  }
}
