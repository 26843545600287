export const DROPDOWN = 'dropdown'
export const FREETEXT = 'freetext'

export const METADATA_INPUT_TYPES = [DROPDOWN, FREETEXT]

export const NOT_NULL = 'NotNull'
export const EQUALS = 'Equals';
export const NOT_EQUALS = "NotEquals"

export const CONDITION_TYPES = [EQUALS, NOT_EQUALS, NOT_NULL]
